"use client";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { getPageName } from "@/utils/helpers";

interface FullScreenHeroProps {
  image: string;
  content?: string;
  colorScheme?: "moss-green" | "midnight-blue" | "cobalt-blue" | "orange" | "neon-rose";
  linkText?: string;
  linkHref?: string;
}

export default function FullScreenHero({
  image,
  content,
  colorScheme = "midnight-blue",
  linkText,
  linkHref,
}: FullScreenHeroProps): React.JSX.Element {
  const pathname = usePathname();
  const pageName = getPageName(pathname) === "" ? "home" : getPageName(pathname);
  return (
    <main className="fullscreen" data-color-scheme={colorScheme} data-page-name={pageName}>
      {content && (
        <div className="content">
          <div className="shaped-content-object">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {linkText && linkHref && (
              <Link
                className="button-with-brand-angle with-margin"
                data-color-scheme={colorScheme}
                href={linkHref}
              >
                {linkText}
              </Link>
            )}
          </div>
        </div>
      )}

      <div className="media">
        <Image
          alt=""
          height={1080}
          placeholder="empty"
          priority={true}
          quality={85}
          sizes="(max-width: 640px) 100vw, (max-width: 1024px) 75vw, (max-width: 1280px) 1024px, 1920px"
          src={image}
          width={1920}
        />
      </div>
    </main>
  );
}
