/* eslint-disable jsx-a11y/alt-text */
"use client";

import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";

const DonateImages: React.FC = () => {
  const commonProps = {
    alt: "Please consider donating to our Foundation",
    placeholder: "empty" as const,
    priority: true,
    sizes: "(max-width: 640px) 100vw, (max-width: 1024px) 75vw, (max-width: 1280px) 1024px, 1920px",
  };

  return (
    <>
      <Image
        {...commonProps}
        className="dialog-image-portrait"
        height={1920}
        src="/img/hero/portrait/we-can-be-heros.jpg"
        width={1080}
      />
      <Image
        {...commonProps}
        className="dialog-image-landscape"
        height={1080}
        src="/img/hero/we-can-be-heros-1920x1080.jpg"
        width={1920}
      />
    </>
  );
};

interface BasicDonateDialogProps {
  showAfter?: number;
}

const BasicDonateDialog: React.FC<BasicDonateDialogProps> = ({ showAfter }) => {
  const [isVisible, setIsVisible] = useState(false);
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClose = (): void => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (showAfter !== undefined) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, showAfter);

      // Cleanup function to clear the timeout if the component unmounts
      return (): void => clearTimeout(timer);
    } else {
      // If no showAfter prop is provided, show the dialog immediately
      setIsVisible(true);
    }
  }, [showAfter]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dialogRef.current && !dialogRef.current.contains(event.target as Node) && isVisible) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible]);

  return (
    <div
      className="dialog donate"
      data-anchor-position="bottom"
      data-is-visible={isVisible.toString()}
      ref={dialogRef}
    >
      <div className="dialog-cell dialog-cell-content">
        <button aria-label="close" className="close-dialog" onClick={handleClose}>
          Close
        </button>
        <h2 className="header">Please Donate</h2>

        <p>
          We know that not everyone is an investor but please consider donating to our Foundation.
        </p>

        <a className="button-link" href="https://thefuturefound.org/donate" rel="noreferrer">
          Donate
        </a>
      </div>
      <div className="dialog-cell dialog-cell-image">
        <a href="https://thefuturefound.org/donate" rel="noreferrer" target="_blank">
          <DonateImages />
        </a>
      </div>
    </div>
  );
};

export default BasicDonateDialog;
